<template>
  <l-default>
    <em style="margin-bottom: 10px">We are suspending our texting service starting the 5th of July.</em>
    <CStickyBtnGroup position="top">
      <CBackButton :to="{ name: 'conversations' }"></CBackButton>
      <CNewConversationButton :target="target" type="message"></CNewConversationButton>
    </CStickyBtnGroup>
    <OTitle>{{ target }}</OTitle>
    <CMessagesList :target="target"></CMessagesList>
  </l-default>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import OTitle from '@/objects/OTitle'
import CMessagesList from '@/components/anonymousTexting/CMessagesList'
import CNewConversationButton from '@/components/anonymousTexting/CNewConversationButton'
import CStickyBtnGroup from '../../components/CStickyBtnGroup'
import CBackButton from '../../components/CBackButton'

export default {
  name: 'PageMessages',
  components: { CBackButton, CStickyBtnGroup, CNewConversationButton, CMessagesList, OTitle, LDefault },
  props: {
    target: {
      type: String,
      required: true
    }
  },
  mounted () {
    document.title = this.target
  }
}
</script>

<style lang="scss" scoped>
.o-title {
  margin-top: $vertical-margin;

  @include two-column() {
    display: block;
  }
}
</style>
