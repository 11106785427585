<template>
  <div class="message-item-container" :class="classes" @click="toggleTooltip">
    <div class="message-item" :class="`message-item-${messageType}`">
      <a
        v-bind="linkMessage ? {href: linkMessage, target: '_blank'} : {}" :is="linkMessage ? 'a' : 'span'"
        @click="onLinkClick"
      >
        <img :src="imageSrc" v-if="imageSrc">
        <span v-else>{{ displayMessage }}</span>
      </a>
    </div>
    <OCRow class="message-item-footer">
      <div class="message-item-date">{{ displayDate }}</div>
      <div class="message-item-status" @mouseenter="showTooltip" @mouseleave="hideTooltip">{{ displayStatus }}</div>
    </OCRow>
    <o-tooltip v-if="displayStatusInfo">
      <p>{{ displayStatusInfo }}</p>
      <p style="color: #FF4E42" v-if="displayRefundedInfo">{{ displayRefundedInfo }}</p>
    </o-tooltip>
    <CPopup v-if="imageSrc" :show="popupShown" @close="onPopupClose">
      <img :src="imageSrc" class="image-full">
    </CPopup>
  </div>
</template>

<script>
import { format } from 'date-fns'
import OCRow from '../../objects/containers/OCRow'
import OTooltip from '../../objects/OTooltip'
import { mapGetters } from 'vuex'
import CPopup from '../CPopup'

// use a shared object between instances to allow only single tooltip to be shown
const sharedData = {
  tooltipOn: null
}

export default {
  name: 'CMessageListItem',
  components: { CPopup, OTooltip, OCRow },
  props: {
    message: {
      type: Object, required: true
    }
  },
  data: () => ({
    shared: sharedData,
    popupShown: false
  }),
  computed: {
    ...mapGetters({
      credits: 'getCredits'
    }),
    classes () {
      return {
        [this.message.direction]: true,
        'o-tooltip-show': this.shared.tooltipOn === this,
        'o-tooltip-container o-tooltip-trigger': this.message.direction === 'outgoing'
      }
    },
    displayDate () {
      const date = new Date(this.message.when * 1000)
      return format(date, 'Pp')
    },
    /**
     * @return {'hidden'|'text'|'image'|'call/incoming'|'call/no-answer'|'call/outgoing'|'external'|null}
     */
    messageType () {
      const status = this.message.status
      const type = this.message.scenario
      const mime = this.message.mime
      if (status === 'hidden' && this.credits === 0) {
        return 'hidden'
      } else {
        switch (type) {
          case 'atext':
            return 'text'
          case 'mms':
            if (/^image\//.test(mime)) {
              return 'image'
            }
            return 'external'
          case 'call':
            if (this.message.direction === 'outgoing') {
              return 'call/outgoing'
            } else if (this.message.status === 'no-answer') {
              return 'call/no-answer'
            }
            return 'call/incoming'
        }
        return null
      }
    },
    displayMessage () {
      switch (this.messageType) {
        case 'hidden':
          return '*** Message received but hidden, since you do not have enough credits. Please, purchase to reveal hidden messages ***'
        case 'text':
          return this.message.text
        case 'image':
          return ''
        case 'external':
          return 'Multimedia message'
        case 'call/incoming':
          return 'Incoming Call'
        case 'call/outgoing':
          return 'Outgoing Call'
        case 'call/no-answer':
          return 'Incoming Call'
        default:
          return 'Unsupported message type'
      }
    },
    linkMessage () {
      switch (this.messageType) {
        case 'external':
        case 'image':
          return this.message.text
        default:
          return ''
      }
    },
    imageSrc () {
      if (this.messageType === 'image') {
        return this.message.text
      }
      return ''
    },
    displayStatus () {
      if (this.message.direction !== 'outgoing') return ''
      switch (this.message.status) {
        case 'delivered':
          return 'delivered'
        case 'sent':
          return 'sent'
        case 'undelivered':
          return 'undelivered'
        case 'failed':
          return 'failed'
        default:
          return ''
      }
    },
    displayStatusInfo () {
      if (this.message.direction !== 'outgoing') return ''
      switch (this.message.status) {
        case 'delivered':
          return 'We have received confirmation of message delivery from the carrier.'
        case 'sent':
          return 'We sent the message. We did not receive delivery information from the carrier yet. Give it some time. If it stays sent, it means the status is unknown(could be delivered or not, but we do not know).'
        case 'undelivered':
          return 'The message was not delivered - it can happen for several reasons, including carrier content filtering, availability of the destination handset, out of range, no battery, etc.'
        case 'failed':
          return 'The message could not be sent - it can happen for various reasons, including queue overflows, account suspensions, the number no longer active, and media errors (in the case of MMS).'
        default:
          return 'This message was sent via older version of this app, so we do not have a message receipt for it.'
      }
    },
    displayRefundedInfo () {
      if (this.message.direction !== 'outgoing') return ''
      switch (this.message.status) {
        case 'undelivered':
        case 'failed':
          return 'We already returned the credit(s) spent for this message.'
        default:
          return ''
      }
    }
  },
  methods: {
    toggleTooltip () {
      if (this.shared.tooltipOn === this) {
        this.hideTooltip()
      } else {
        this.showTooltip()
      }
    },
    showTooltip () {
      this.shared.tooltipOn = this
    },
    hideTooltip () {
      this.shared.tooltipOn = null
    },
    onLinkClick (e) {
      if (this.messageType === 'image') {
        e.preventDefault()
        this.popupShown = true
      }
    },
    onPopupClose () {
      this.popupShown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.message-item {
  a, .o-link {
    color: inherit;
  }

  &-container {
    position: relative;

    padding: 0.25em 0.5em;
    margin: 0.25em 0;
    max-width: 75%;
    width: fit-content;

    @include two-column {
      // remove the padding due to share-this from list
      // so the container inside item can align with rest of the page
      margin: 0.25em (-($share-this-width + $list-margin)) 0 (- $list-margin);
    }

    border-radius: 0.5rem;
    //border: 1px solid #0F0F0F;
    .message-item-image {
      position: relative;
      padding-top: 100%;
      min-width: 100%;
      width: calc(min(50vw, 50vh) - #{vr(2*1.25)});
      @include two-column() {
        width: calc(min(25vw, 25vh) - #{$horizontal-margin});
      }

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
      }
    }

    font-size: 0.75rem;

    &.outgoing {
      margin-left: auto;
      background: #fff;
      color: #808080;

      .message-item {
        color: #000;
      }

      .message-item-status {
        color: $primary;
      }
    }

    &.incoming {
      margin-right: auto;
      background: $primary;
      color: #3D3D3D;

      .message-item {
        color: #fff;
      }

      .message-item-status {
        display: none;
      }
    }

    // clearfix
    &::after {
      content: "";
      clear: both;
      display: table;
    }

    .image-full {
      display: block;
      object-fit: contain;
      object-position: center;
      max-width: inherit;
      max-height: inherit;
      margin: auto;
    }
  }

  &-message {
    font-size: 1.25em;
    word-break: break-word;
    overflow: hidden;
  }

  &-footer {
    > * {
      flex: 0 1 50%;
    }
  }

  &-status, &-date {
    white-space: nowrap;
  }

  &-status {
    margin-left: 0.5em;
    text-align: right;
  }
}
</style>
