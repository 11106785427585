<template>
  <OCColumn class="messages-list-container">
    <CLoading v-if="loading"></CLoading>
    <div v-if="!loading && messages && messages.length === 0">
      No messages.
    </div>
    <div v-if="!success">
      Error loading messages. Please try again later.
    </div>
    <ul class="messages-list-list" v-if="messages && messages.length > 0">
      <li class="messages-list-item" v-for="message in messages" :key="message.logid">
        <CMessageListItem :message="message"/>
      </li>
    </ul>
  </OCColumn>
</template>

<script>
import CMessageListItem from './CMessageListItem'
import CLoading from '@/components/CLoading'
import OCColumn from '@/objects/containers/OCColumn'
import { listMessages } from '@/services/anonymousTexting'

export default {
  name: 'CMessagesList',
  components: { OCColumn, CLoading, CMessageListItem },
  props: {
    target: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    success: true,
    /**
     * @type {import('../services/anonymousTexting').Message[]}
     */
    messages: null
  }),
  methods: {
    async load () {
      this.loading = true
      this.success = true
      try {
        const { success, data: { logs } } = await listMessages({
          user: this.$store.getters.getEmail,
          pass: this.$store.getters.getPassword,
          target: this.target,
          // limit: 1,
          direction: 'before',
          sort: 'DESC'
        })
        this.success = success
        this.messages = logs
      } catch (e) {
        this.success = false
      } finally {
        this.loading = false
      }
    },
    async refresh () {
      if (!this.loading) {
        try {
          const { success, data: { logs } } = await listMessages({
            user: this.$store.getters.getEmail,
            pass: this.$store.getters.getPassword,
            target: this.target,
            // limit: 1,
            direction: 'before',
            sort: 'DESC'
          })
          if (success) {
            this.messages = logs
          }
        } catch (e) { }
      }
      this.loader = setTimeout(() => this.refresh(), 5000)
    }
  },
  async mounted () {
    await this.load()
    this.loader = setTimeout(() => this.refresh(), 5000)
  },
  beforeDestroy () {
    clearTimeout(this.loader)
  }
}
</script>

<style lang="scss" scoped>
.messages-list {
  &-container {
    flex-grow: 1;
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    @include two-column() {
      // accommodate the share-this bar on the right
      padding: 0 ($share-this-width + $list-margin) 0 $list-margin;
    }
  }

  &-item {
  }
}
</style>
